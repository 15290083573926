<template>
  <div>
    <el-form class="detail-form-content" ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="单词名称" prop="title">
        <el-input v-model="ruleForm.title" placeholder="单词名称" clearable />
      </el-form-item>
      <el-form-item label="单词发音" prop="radio">
        <el-input v-model="ruleForm.radio" placeholder="单词发音" clearable />
      </el-form-item>
      <el-form-item label="所属章节" prop="consultationId">
        <el-select v-model="ruleForm.consultationId" placeholder="请选择单词所属章节">
          <el-option v-for="(item,index) in consultationList" :label="item.name" :value="item.id" :key="index"></el-option> 
        </el-select>
      </el-form-item>
      <el-form-item label="例句" prop="result">
        <el-input v-model="ruleForm.result" placeholder="例句" clearable />
      </el-form-item>
      <el-form-item label="中文翻译" prop="chinese">
        <el-input v-model="ruleForm.chinese" placeholder="请输入中文翻译" clearable />
      </el-form-item>
      <el-form-item label="详细介绍" prop="explains">
        <el-input v-model="ruleForm.explains" placeholder="详细介绍" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import userconsultsubApi from '@/api/userConsultSub.js'
import consultationApi from '@/api/consultation.js'
export default {
  components: {
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        content: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
      },
      consultationList: [],
      page:{
          current:1,
          size: 10
        }, 
    };
  },
  props: ["parent"],
  mounted() {
    this.getConsultationList();
  },
  methods: {
    // 初始化
    init(id) {
      userconsultsubApi.getDetail(id).then(res => {
        this.ruleForm = res.data;
      });
    },
    getConsultationList() {
      consultationApi.getList(this.page).then(res => {
        console.log(res);
        this.consultationList = res.data.records;
      })
    },
    // 提交
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            userconsultsubApi.update(this.ruleForm).then(res => {
              this.$message.success("操作成功");
              this.parent.showFlag = false;
              this.parent.search();
            });
          } else {
            userconsultsubApi.add(this.ruleForm).then(res => {
              this.$message.success("操作成功");
              this.parent.showFlag = false;
              this.parent.search();
            });
          }
        }
      });
    },
    // 返回
    back() {
      this.parent.showFlag = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  height: 500px;
}
</style>
