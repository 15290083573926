<template>
    <div class="main-content">
      <!-- 列表页 -->
      <div v-if="!showFlag">
        <el-form :inline="true" :model="searchForm" class="form-content">
          <el-form-item>
            <el-button type="primary" @click="addOrUpdateHandler()" round>新增</el-button>
          </el-form-item>
        </el-form>
        <div class="table-content">
          <el-table
            :data="dataList"
            border
            v-loading="dataListLoading"
            style="width: 100%;"
          >
            <el-table-column prop="id" label="索引id" align="center" :show-overflow-tooltip="true"/>
            <el-table-column prop="name" label="章节标题" align="center" :show-overflow-tooltip="true"/>
  
            <el-table-column prop="createTime" label="创建时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime.substring(0,16) }}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              header-align="center"
              align="center"
              width="150"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="addOrUpdateHandler(scope.row)"
                >修改</el-button>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                  @click="deleteHandler(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="page.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="page.size"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
            class="pagination-content"
          ></el-pagination>
        </div>
      </div>
      <!-- 添加/修改页面  将父组件的search方法传递给子组件-->
      <add-or-update v-else :parent="this" ref="addOrUpdate"></add-or-update>
    </div>
  </template>
  <script>
  import consultationApi from '@/api/consultation.js'
  import AddOrUpdate from "./add-or-update";
  export default {
    data() {
      return {
        searchForm: {},
        dataList: [],
        page:{
          current:1,
          size: 10
        }, 
        totalPage: 0,
        dataListLoading: false,
        showFlag: false,
        dataListSelections: []
      };
    },
    mounted() {
      this.getDataList();
    },
    components: {
      AddOrUpdate
    },
    methods: {
      search() {
        this.page.current = 1;
        this.getDataList();
      },
      // 获取数据列表
      getDataList() {
        this.dataListLoading = true;
        consultationApi.getList(this.page).then(res => {
          this.dataList = res.data.records;
          this.totalPage = res.data.total;
          this.dataListLoading = false;
        });
      },
      // 每页数
      sizeChangeHandle(val) {
        this.page.size = val;
        this.page.current = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.page.current = val;
        this.getDataList();
      },
      // 添加/修改
      addOrUpdateHandler(row) {
        this.showFlag = true;
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(row.id);
        });
      },
      // 删除
      deleteHandler(id) {
        this.$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
            consultationApi.delete(id).then(res => {
            this.$message.success("删除成功"); 
            this.search(); 
          });
        });
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  </style>
  